<template>
  <header class="header">
    <!-- Logo -->
    <div class="menu-wrap">
      <img
        src="../../../public/assets/rimanagement.png"
        alt=""
        class="logo-style"
      />
      <nav>
        <!-- Menu Button -->
        <input
          class="menu-btn"
          type="checkbox"
          id="menu-btn"
          aria-label="Toggle Navigaton Menu"
        />
        <!-- Icon -->
        <label class="menu-icon" for="menu-btn">
          <span class="navicon"></span>
        </label>
        <!-- Menu -->
        <ul class="menu">
          <li>
            <router-link
              to="/"
              :class="{
                'header-button-style': true,
                'active-route': isHomeRoute,
              }"
            >
              R&I Management
            </router-link>
          </li>
          <li>
            <router-link
              to="/leadership"
              class="header-button-style"
              :class="{ 'active-route': !isHomeRoute && !isArticlesPage }"
            >
              Leadership
            </router-link>
          </li>
          <li>
            <router-link
              to="/articles"
              class="header-button-style"
              :class="{ 'active-route': !isHomeRoute && !isLeadershipPage }"
            >
              Articles and Guides
            </router-link>
          </li>
          <li>
            <a
              href="https://rimanagement.koreconx.com/login#/ "
              class="header-button-style"
              >Shareholder Login</a
            >
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script setup>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const isHomeRoute = ref(false);
const isLeadershipPage = ref(false);
const isArticlesPage = ref(false);

watch(
  route,
  () => {
    isHomeRoute.value = route.path === "/";
    isLeadershipPage.value = route.path === "/leadership";
    isArticlesPage.value = route.path === "/articles";
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped>
@import "./HeaderSection.scss";
</style>
