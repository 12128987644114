<template>
  <section class="bc-card" :style="{ backgroundImage: `url(${props.bgImg})` }">
    <div class="dark-overlay"></div>
    <div class="bc-article-description-container">
      <p class="bc-card-title">
        {{ props.title }}
      </p>
      <button class="tc-article-count">{{ props.label }}</button>
    </div>
  </section>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  bgImg: {
    type: String,
    default: "",
  },

  title: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  subHeader: {
    type: String,
    default: "",
  },
});
</script>

<style scoped lang="scss">
@import "./CategoryCard.scss";
</style>
