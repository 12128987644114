<template>
  <section id="staff-description-id">
    <StaffCard
      v-for="(staff, index) in staffCards"
      :key="index"
      :imgString="staff.imgString"
      :name="staff.name"
      :title="staff.title"
      :description="staff.description"
    />
  </section>
</template>

<script setup>
import StaffCard from "@/components/Assets/StaffCard";
import { useStaffsStore } from "@/stores/useStaffStore/staff";
import { storeToRefs } from "pinia";

const staffsStore = useStaffsStore();
const { staffCards } = storeToRefs(staffsStore);
</script>

<style lang="scss">
@import "./StaffDescriptionSection.scss";
</style>
