import { ref } from "vue";
import { defineStore } from "pinia";

export const useArticlesStore = defineStore("articles", () => {
  const articleCards = ref([
    {
      title: "Your Ultimate Guide to Buying a Home or Investment Property",
      description:
        "In this comprehensive guide, we'll walk you through the essential steps of buying a home or investment property, from understanding the market to closing the deal.",
      backgroundImage: "/images/articles-and-guides/carousel-1.png",
    },
    {
      title: "Your Ultimate Guide to Buying a Home or Investment Property",
      description:
        "In this comprehensive guide, we'll walk you through the essential steps of buying a home or investment property, from understanding the market to closing the deal.",
      backgroundImage: "/images/articles-and-guides/carousel-2.png",
    },
    {
      title: "Your Ultimate Guide to Buying a Home or Investment Property",
      description:
        "In this comprehensive guide, we'll walk you through the essential steps of buying a home or investment property, from understanding the market to closing the deal.",
      backgroundImage: "/images/articles-and-guides/carousel-3.png",
    },
  ]);

  const categoryCards = ref([
    {
      title: "Real Estate",
      description:
        "In this comprehensive guide, we'll walk you through the essential steps of buying a home or investment property, from understanding the market to closing the deal.",
      backgroundImage: "/images/articles-and-guides/carousel-1.png",
    },
    {
      title: "Technology Services",
      description:
        "In this comprehensive guide, we'll walk you through the essential steps of buying a home or investment property, from understanding the market to closing the deal.",
      backgroundImage: "/images/articles-and-guides/carousel-2.png",
    },
    {
      title: "Asset Management",
      description:
        "In this comprehensive guide, we'll walk you through the essential steps of buying a home or investment property, from understanding the market to closing the deal.",
      backgroundImage: "/images/articles-and-guides/carousel-3.png",
    },
  ]);

  const blogCards = ref([
    {
      title: "Your Ultimate Guide to Buying a Home or Investment Property",
      subHeader: `In this comprehensive guide, we'll walk you through the essential steps of buying a home or investment property, from understanding the market to closing the deal.`,
      backgroundImage: "/images/articles-and-guides/carousel-1.png",
      label: "Real Estate",
    },
    {
      title: "Your Ultimate Guide to Buying a Home or Investment Property",
      subHeader: `In this comprehensive guide, we'll walk you through the essential steps of buying a home or investment property, from understanding the market to closing the deal.`,
      backgroundImage: "/images/articles-and-guides/carousel-1.png",
      label: "Technology Services",
    },
    {
      title: "Your Ultimate Guide to Buying a Home or Investment Property",
      subHeader: `In this comprehensive guide, we'll walk you through the essential steps of buying a home or investment property, from understanding the market to closing the deal.`,
      backgroundImage: "/images/articles-and-guides/carousel-1.png",
      label: "Asset Management",
    },
  ]);
  return {
    articleCards,
    blogCards,
    categoryCards,
  };
});

export default { useArticlesStore };
