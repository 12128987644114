<template>
  <div id="articles-title-section-id">
    <Carousel
      id="carousel-section-id"
      :value="articleCards"
      :numVisible="1"
      :numScroll="1"
      class="custom-carousel"
      circular
      :autoplayInterval="6000"
      :showIndicators="true"
    >
      <template #item="slotProps">
        <div
          class="carousel-item"
          :style="{ backgroundImage: `url(${slotProps.data.backgroundImage})` }"
        >
          <div class="dark-overlay"></div>
          <div class="title-container">
            <h3>{{ slotProps.data.title }}</h3>
            <p>{{ slotProps.data.description }}</p>
          </div>
        </div>
      </template>
    </Carousel>
    <div class="articles-body">
      <section class="top-categories-section">
        <p class="article-title">Top Categories</p>
        <div class="tc-cards-wrapper">
          <CategoryCard
            v-for="(categoryCard, index) in categoryCards"
            :key="index"
            :bgImg="categoryCard.backgroundImage"
            :title="categoryCard.title"
          />
        </div>
      </section>
      <section class="recent-blogs-section">
        <p class="article-title">Recent Blog Posts</p>
        <div class="tc-cards-wrapper">
          <BlogCard
            v-for="(blogCard, index) in blogCards"
            :key="index"
            :bgImg="blogCard.backgroundImage"
            :title="blogCard.title"
            :label="blogCard.label"
            :subHeader="blogCard.subHeader"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
import Carousel from "primevue/carousel";
import { useArticlesStore } from "@/stores/useArticleStore/articles";
import CategoryCard from "../../Assets/ArticlesAndGuides/CategoryCard.vue";
import BlogCard from "../../Assets/ArticlesAndGuides/BlogCard.vue";
import { storeToRefs } from "pinia";

const articlesStore = useArticlesStore();
const { articleCards } = storeToRefs(articlesStore);
const { categoryCards } = storeToRefs(articlesStore);
const { blogCards } = storeToRefs(articlesStore);
</script>

<style lang="scss">
@import "./ArticlesTitleSection.scss";
</style>
