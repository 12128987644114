import { createApp } from "vue";
import { createPinia } from "pinia";
import "animate.css";
import App from "./views/App.vue";
import router from "./router";
import PrimeVue from "primevue/config";
import "primeicons/primeicons.css"; // Icons

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(PrimeVue);
app.mount("#app");
